import React from "react";
import "./Contact.css";
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Contact() {
  const handelcustomercare = () => {
    // const phoneNumber = "1234567890";
    // window.open(`tel:${phoneNumber}`, '_blank');
  };
  const navigate = useNavigate();
  const handelcustomerbranch = () => {
    // const phoneNumber = "1234567890";
    // window.open(`tel:${phoneNumber}`, '_blank');
  };
  return (
    <div>
      
      <div className="">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#ff8603"
            fillOpacity="1"
            d="M0,192L60,165.3C120,139,240,85,360,80C480,75,600,117,720,117.3C840,117,960,75,1080,48C1200,21,1320,11,1380,5.3L1440,0L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
          ></path>
        </svg>
      </div>

      <div className="d-flex justify-content-center align-items-center flex-column mobile-view">
        <div className="text-center ">
          <div className="at-container">
            <div className="at-item">
              {" "}
              <h1 style={{ fontFamily: 'heading' }}>Welcome to Quick to Home...🐔</h1>
              <h5 style={{ fontFamily: 'branch-name' }}>We are happy to serve you!!!</h5>
            </div>
          </div>
        </div>
        <div>
          <button className="btn-call" onClick={handelcustomercare()}>
          <FontAwesomeIcon icon={faPhone} /> Customer Care
          </button>
          <button className="btn-call" onClick={handelcustomerbranch()}>
          <FontAwesomeIcon icon={faPhone} /> Customer Branch
          </button>
        </div>
      </div>

      <div className="bottom-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#ff8603"
            fillOpacity="1"
            d="M0,128L80,133.3C160,139,320,149,480,176C640,203,800,245,960,234.7C1120,224,1280,160,1360,128L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default Contact;
