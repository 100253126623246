import React, { useState ,useEffect,useRef } from "react";
import "./Sidebar.css";
import { FaHeart, FaPhone, FaRegComment } from "react-icons/fa";
import { MdOutlinePayment } from "react-icons/md";
import { MdOutlineLocationCity } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { PiHouse, PiShoppingCartSimpleFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
function Sidebar() {
  const navigate = useNavigate();
  const hamburgerRef = useRef(null);
  const handleOrders = (orders) => {
    navigate(orders);
    console.log("Clicked");
  };
  const [showSidebar, setShowSidebar] = useState(false);
  const handlefeedback = (feedback) => {
    navigate(feedback);
    console.log(`Handling feedback: ${feedback}`);
  };
  const handlecontact = (contact) => {
    navigate(contact);
    console.log(`Handling feedback: ${contact}`);
  };
  const handleaddresses = (address) => {
    navigate(address);
    console.log(`Handling feedback: ${address}`);
  };
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (hamburgerRef.current && hamburgerRef.current.contains(e.target)) {
        // Clicked on the hamburger menu button, don't close the sidebar
        return;
      }

      const sidebar = document.querySelector('.sidebar');
      if (sidebar && !sidebar.contains(e.target)) {
        setShowSidebar(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  return (
    <div className="sideside_head">
    <nav className="navbar">
      <div className="navbar-header">
        <button className="navbar-toggler" onClick={toggleSidebar} ref={hamburgerRef}>
          <GiHamburgerMenu />
        </button>
      </div>
    </nav>
    <div className={`sidebar ${showSidebar ? 'show' : ''}`}>
      <div className="sidehead">
      <p className="fav1" onClick={() => handleOrders('mainpage')}>
          <PiHouse />
          Home
        </p>
        <p className="fav1" onClick={() => handleOrders('orders')}>
          <PiShoppingCartSimpleFill />
          Orders
        </p>

        <p className="fav1" onClick={() => handleaddresses('address')}>
          <MdOutlineLocationCity />
          Address
        </p>
        <p className="fav1" onClick={() => handlefeedback('feedback')}>
          <FaRegComment />
          Feedback
        </p>
        <p className="fav1" onClick={() => handlecontact('contact')}>
        <FontAwesomeIcon icon={faPhone} />
          Contact
        </p>
      </div>
    </div>
  </div>
  );
}

export default Sidebar;
