import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import Header from '../../Header/Header'
import "./OrderLayout.css"

function OrderLayout() {
  return (
    <div className='top-head'>
        <Header/>
        <div className='content_space'>
        <Sidebar/>
         <Outlet/>
        </div>
    </div>
  )
}

export default OrderLayout