import React from 'react'
import Privacy from '../Policies/Privacy/Privacy'
import { Outlet } from 'react-router-dom'
import './Layout.css'

function Layout() {
  return (
    <div>
        <div className='content_space'>
      <Privacy/>
      <Outlet/>
      </div>
    </div>
  )
}

export default Layout
