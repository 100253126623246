import React, { useEffect, useState } from "react";
import "./OrderHistory.css";
import orderempty from "../../Assests/3298067.jpg";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { FaCartShopping, FaBicycle } from "react-icons/fa6";
import { GiDuck, GiHouse } from "react-icons/gi";
function OrderHistory() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);

  const [ordersReoder, setOrderReoder] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const location = useLocation();
  const [view, setView] = useState(false);
  const userName = location.state?.userName || "";
  const [showModalReoder, setShowModalReoder] = useState(false);
  const [history, setHistory] = useState([]);
  console.log(history)
  // console.log("tes1", history);
  // useEffect(() => {
  //   handleOrders();
  // }, []);
  // const handleViewDetails = (order) => {
  //   setSelectedOrder(order);
  //   setShowModal(true);
  // };

  const handleOrders = (id) => {
    fetch(
      `https://falconsquare.in/RN_test/q2h-test/admin/api/order_details.php?order_id=${id}`
    )
      .then((response) => response.json())
      .then((data) => {
        setOrders(data.order_details);
        setSelectedOrder(id);
        setShowModal(true);
      })
      .catch((err) => console.log(err));
  };

  const handleReoder = (id) => {
    fetch(
      `https://falconsquare.in/RN_test/q2h-test/admin/api/order_details.php?order_id=${id}`
    )
      .then((response) => response.json())
      .then((data) => {
        setOrderReoder(data.order_details);
        setSelectedOrder(id);
        setShowModalReoder(true);
      })
      .catch((err) => console.log(err));
  };

  const handleReoderConfirm = (id) => {
    console.log("data", id);
  };

  const getOrderHistory = async () => {
    const uid = localStorage.getItem("user_id");
    const bid = localStorage.getItem("branchid")
    const url = await fetch(
      `https://falconsquare.in/RN_test/q2h-test/admin/api/order_history.php?user_id=${uid}&bid=${bid}`
    );
    const response = await url.json();

    // console.log("tes2",response.order)
    setHistory(response.order);
  };

  useEffect(() => {
    getOrderHistory();
  }, []);
  // const placedOrderIndex = history.filter(
  //   (rams) => rams.status === "Order is Placed"
  // );

  return (
    <div className="container ">
      {history.length === 0 ? (
        <div className="d-flex flex-column align-items-center">
          <img
            src={orderempty}
            alt="orderempty"
            className="img-addtocart img-fluid"
          />
          <button className="btn-order" onClick={() => navigate("/mainpage")}>
            Go to Homepage
          </button>
        </div>
      ) : (
        <div className="order_main">
          <div className="order_1 d-flex justify-content-center mt-5  ">
            <h3 className="ot">ORDER HISTORY</h3>
          </div>
          <div className="row d-flex justify-content-center  g-5 top-secpad">
            {history && history.map((item, index) => (
              <div className="col-sm-12 " key={index}>
                <div className="card p-3 branch_card_page">
                  <h4 style={{ fontFamily: "heading" }}>{item.bname}</h4>
                  <h4 style={{ fontFamily: "description" }}>
                    <span className="order_h">Order#</span>
                    {item.order_no} | {item.date}
                  </h4>
                  <a
                    className="link"
                    onClick={() => handleOrders(item.order_no)}
                  >
                    View Details
                  </a>
                  <div className="orderline m-2"></div>
                  <div className="btn_head">
                    <div className="btn_align">
                      {/* <button
                        className="reorderbtn"
                        onClick={() => handleReoder(item.order_no)}
                      >
                        REORDER
                      </button> */}
                      <button className="helpbtn">HELP</button>
                    </div>
                    <div>
                      <h4 className="order_h">
                        Order Amount:{item.total_amount}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Modal
        show={showModalReoder}
        onHide={() => setShowModalReoder(false)}
        className="right-aligned-modal"
        animation={false}
      >
        <Modal.Body>
          <h4
            className="d-flex justify-content-center"
            style={{ borderBottom: "1px solid #ddd", paddingBottom: "10px" }}
          >
            Order Status
          </h4>
          {ordersReoder.map((list, index) => (
            <div key={index} className="status-wrapper">
              <div className="container-fluid">
                {list.menu.map((item, idx) => (
                  <div key={idx} className="table-responsive">
                    <table className="table 2">
                      <tbody>
                        <tr>
                          <td className="text-start  txt-font name-alg">
                            {item.ItemName}
                          </td>
                          <td className="text-start  txt-font name-alg1">
                            {item.ItemQty}
                          </td>
                          <td className="text-start txt-font name-alg1">
                            ₹{Math.round(item.ItemTotalPrice)}
                          </td>
                        </tr>
                        {item.variant.map((variantItem, variantIdx) => (
                          <tr key={variantIdx}>
                            <td className="text-start txt-font name-alg">
                              {variantItem.variant_name}
                            </td>
                            <td className="text-start txt-font name-alg1">
                              {variantItem.heading}
                            </td>
                            <td className="text-start txt-font name-alg1">
                              ₹{Math.round(variantItem.price)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <button
                      className="btn-confirm"
                      onClick={() => handleReoderConfirm(list.order_no)}
                    >
                      Confirm
                    </button>
                    <button
                      className="btn-can"
                      onClick={() => setShowModalReoder(false)}
                    >
                      Cancel
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Modal.Body>
      </Modal>

      <div className="container-fluid modal-history">
        <div className="row">
          <div className="col-lg-8 offset-lg-4  ">
            <Modal
              Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              size="lg"
              dialogClassName="fixed_right"
            >
              <Modal.Header closeButton>
                <Modal.Title>Order Status</Modal.Title>
              </Modal.Header>
              <Modal.Body className="custom-modal-body">
                <div className="m-1 ">
                  <div className="status-container">
                    {orders.map((list, index) => (
                      <div key={index} className="status-wrapper">
                        <div className="status-item">
                          {/* {console.log("list", list.status)} */}
                          <p>OrderPlaced</p>
                          <FaCartShopping
                            className={
                              list.status_name === "Order Placed"
                                ? "icon-order-dark"
                                : "icon-order"
                            }
                          />
                        </div>
                        <div className="status-item">
                          <p>Preparing</p>
                          <GiDuck
                            className={
                              list.status_name === "Preparing"
                                ? "icon-order-dark"
                                : "icon-order"
                            }
                          />
                        </div>
                        <div className="status-item">
                          <p>Dispatching</p>
                          <FaBicycle
                            className={
                              list.status_name === "Activate"
                                ? "icon-order-dark"
                                : "icon-order"
                            }
                          />
                        </div>
                        <div className="status-item">
                          <p>Delivered</p>
                          <GiHouse
                            className={
                              list.status_name === "Order Delivered"
                                ? "icon-order-dark"
                                : "icon-order"
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  {orders.map((list, index) => (
                    <div key={index} className="card status p-2 m-2">
                      {" "}
                      <h6 className="d-flex justify-content-center fw-bold line">
                        ORDER DETAILS
                      </h6>
                      <div className="line-doted d-flex justify-content-between">
                        {list.address === 0 ? (
                          <>
                            <p className="text-start bold txt-font">
                              Order type:
                            </p>
                            <p className="text-end bold txt-font">
                              Self Pickup
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="text-start bold txt-font">
                              Order type:
                            </p>
                            <p className="text-end bold  txt-font">
                              Home Delivery
                            </p>
                          </>
                        )}
                      </div>
                      <div className="table-container text-left mt-3">
                        <table id="table-to-xls" className="text-left">
                          <tbody>
                            {list.menu.map((item, idx) => (
                              <React.Fragment key={idx}>
                                {/* Row for item details */}
                                <tr>
                                  <td className="td-item">{item.ItemName}</td>
                                  <td className="td-item-1">{item.ItemQty}</td>
                                  <td className="td-item-2">₹{Math.round(item.ItemTotalPrice)}</td>
                                </tr>
                                {/* Rows for variant details */}
                                {item.variant.map((variantItem, variantIdx) => (
                                  <tr key={variantIdx}>
                                    <td  className="td-item">{variantItem.variant_name}</td>
                                    <td  className="td-item-1">{variantItem.heading}</td>
                                    <td className="td-item-2">₹{Math.round(variantItem.price)}</td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>


                      <div className="container">
                        <h5 className="d-flex justify-content-center fw-bold line">
                          PAYMENT DETAILS
                        </h5>
                        <div className="">
                          <div className=" line-doted">
                            {" "}
                            {/* Reduced padding */}
                            <div className="sub-row">
                              <div>
                                <tr className="subtotal">
                                  <th className="mb">Subtotal:</th>
                                  <th className="mb">Delivery Charge:</th>
                                  <th className="mb">Discount:</th>
                                  <th className="mb">Tips:</th>
                                  <th className="total-price">Total Price :</th>
                                </tr>
                              </div>
                              <div>
                                <tr className="sub-price">
                                  <th className="mb">
                                    ₹{Math.round(list.subtot)}
                                  </th>
                                  <th className="mb">₹{Math.round(list.dc)}</th>
                                  <th className="mb">
                                    ₹{Math.round(list.discount)}
                                  </th>
                                  <th className="mb">₹{Math.round(list.tips)}</th>
                                  <th className="total-price"> ₹{Math.round(list.total_price)}</th>
                                </tr>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div >
                            <tr className="subtotal_1">
                           
                          
                            </tr>
                            
                          </div>
                          */}


                      </div>
                    </div>


                  ))}
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
