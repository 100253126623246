
import { initializeApp } from "firebase/app";
import {getToken, getMessaging, onMessage} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDI2mOMwYtvF0GtN6eh3h6ImypaFA5s4s4",
  authDomain: "haribhavanam-335c9.firebaseapp.com",
  databaseURL: "https://haribhavanam-335c9.firebaseio.com",
  projectId: "haribhavanam-335c9",
  storageBucket: "haribhavanam-335c9.appspot.com",
  messagingSenderId: "168451462501",
  appId: "1:168451462501:web:3a4e2b88ad0e03eadc3d8c",
  measurementId: "G-GLZ47GGD01"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);



export const registerSw = async() => {
  const registration = await navigator.serviceWorker.register('./firebase-messaging-sw.js');
  return registration;
}

export const Token= async() => {
  
  return getToken( messaging,{
    vapidKey: "BILRjeYF7GfRpoJ8_KtP-O9K7Wz4IYKsCGTFUXH9dBDglZTSSsv113QmV2ClVvZHUZMAN7LpeoXtbsGVbxb6n1E"
  }).then( async (token) => {
    console.log(token);
   await localStorage.setItem('token',token);
  })

}

// export const requestPermission = () => {
//   console.log("Request Firebase"); 
//   Notification.requestPermission().then((permision) => {
//     if(permision == 'granted') {
//       return getToken( messaging,{
//         vapidKey: "BILRjeYF7GfRpoJ8_KtP-O9K7Wz4IYKsCGTFUXH9dBDglZTSSsv113QmV2ClVvZHUZMAN7LpeoXtbsGVbxb6n1E"
//       }).then( async (token) => {
//        await localStorage.setItem(token,'token');
//       })
//     }
//   })
// } 
// requestPermission();
Token();
registerSw();




