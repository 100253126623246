import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"; // Imported Navigate
import { Provider } from "react-redux";
import Login from "./Components/Login/Login";
import Policy from "./Components/Policy/Policy";
import Refund from "./Components/Refund/Refund";
import Terms from "./Components/Terms/Terms";
import Delivery from "./Components/Delivery/Delivery";
import Mainhome from "./Components/Mainhome/Mainhome";
import Layout from "./Components/Layout/Layout";
import store from "./Components/Redux/cartStore";
import BranchPage from "./Components/BranchPage/BranchPage";
import Cartscreen from "./Components/Cartscreen/Cartscreen";
import Orderstatus from "./Orderstatus/Orderstatus";
import OrderHistory from "./Components/OrderHistory/OrderHistory";
import OrderLayout from "./Components/OrderLayout/OrderLayout";
import Feedback from "./Components/Feedback/Feedback";
import Contact from "./Components/Contact/Contact";
import Address from "./Components/Address/Address";

// const isLoggedIn = localStorage.getItem("isLoggedIn");

// console.log(isLoggedIn);

function App() {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/BranchPage" element={<BranchPage />} />
            <Route path="/Mainpage" element={<Mainhome />} />
            <Route path="/Cartscreen" element={<Cartscreen />} />
            <Route path="/Orderstatus" element={<Orderstatus />} />
            <Route element={<Layout />}>
              <Route path="/login/Privacypolicy" element={<Policy />} />
              <Route path="/login/Refundpolicy" element={<Refund />} />
              <Route path="/login/Termsofservice" element={<Terms />} />
              <Route path="/login/Deliverypolicy" element={<Delivery />} />
            </Route>
            <Route element={<OrderLayout />}>
              <Route path="/orders" element={<OrderHistory />} />
              <Route path="/address" element={<Address />} />
             
            </Route>
            <Route path="/feedback" element={<Feedback />} />
              <Route path="/contact" element={<Contact />} />
            <Route
              path="/"
              element={
                isLoggedIn ? (
                  <Navigate to="/BranchPage" replace />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
          </Routes>

        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
