import React from 'react'
import './Delivery.css'
import Footer from '../Footer/Footer'

function Delivery() {
  return (
    <div className='del'>
   
      <div className='delivery_head'>
       <h2>Delivery Policy</h2>
       This Delivery Policy is part of our Terms of Use ("Terms") and should be therefore read alongside our main Terms.
    Please carefully review our Delivery Policy when purchasing our products. This policy will apply to any order you place with us.
      </div>
      <div className='delivery_head2'>
        <h3>WHAT ARE MY DELIVERY OPTIONS?</h3>
        <h5>In-Store Pickup</h5>
        In-store and curbside pickup is available for all. Pickups are available all day with a given time.
        <h5>Cash on Delivery</h5>
        In-store and curbside pickup is available for all. Pickups are available all day with a given time.
      </div>
      <div className='delivery_head3'>
        <h3>Delivery Fees</h3>
        We offer delivery at the following rates:
        
        <div className='line_by_line'>
            <div className='line_row1'>
              <h5>Delivery Method:</h5>
              <p>Cash On Delivery</p>
              <p>Online Payment</p>
            </div>
            <div className='line_row2'>
               
               <h5>Delivery Fee:</h5>
               <p>Based on Km</p>
               <p>Based on Km</p>
            </div>
           
            
        </div>
      
       
            All times and dates given for delivery of the products are given in good faith but are estimates only
      </div>
      <div className='delivery_4'>
        <h3>QUESTIONS ABOUT RETURNS?</h3>
       If you have questions about returns, please review our Return Policy:Return Policy.
      </div>
      <div className='delivery_5'>
        <h3>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3>
       If you have any further questions or comments, you may contact us.
      </div>

 
   
    </div>
  )
}

export default Delivery
