export const Reducer = (state = {}, action) => {
    const TotelQtyPrice = (state = []) => {
        // console.log("state",state);
        let [price, items,menu] = [0, 0,[]];
         
        for (let catIndex = 0; catIndex < state.length; catIndex++) {
            for (
                let itemIndex = 0;
                itemIndex < state[catIndex].data.length;
                itemIndex++
            ) {
                if (state[catIndex].data[itemIndex].qty > 0) {
                    menu =[...state]
                    price +=
                        parseFloat(state[catIndex].data[itemIndex].qty) *
                        parseFloat(state[catIndex].data[itemIndex].price);
                    items += parseFloat(state[catIndex].data[itemIndex].qty);
                }
            }
        }
        return { price, items,menu };
    };

    switch (action.type) {

        //  overallItems
        case 'ALL_ITEM':
            // console.log("iii",action.payload);
            return {
                ...state,
                items: action.payload,
            };

        //   addFrom increment qty
        case 'Add_Items':
            const { item1, cat } = action.payload;
            console.log("pokkk",item1,cat);
            const newState = { ...state };
            newState.items[item1].data[cat].qty = Number(newState.items[item1].data[cat].qty) + 1;
            const updatedTotelPrice = TotelQtyPrice(newState.items);
            return {
                ...newState,
                TotelPrice: updatedTotelPrice,
            };

        // remove  dectrement from qty
        case 'REMOVE_FROM_CART':
            const { item, cat1 } = action.payload;
            const newState1 = { ...state };
            newState1.items[item].data[cat1].qty -= 1;
            const updatedTotelPrice1 = TotelQtyPrice(newState1.items);
            return {
                ...newState1,
                TotelPrice: updatedTotelPrice1,
            };


        default:
            return state;
    }
};


