import React from 'react'
import './Privacy.css'
import q2h from '../../../Assests/q2h.png'
import { useNavigate } from 'react-router-dom'
function Privacy() {
  const navigate = useNavigate();
  const handleNavigate = (str) => {
    navigate(str);
  };
  return (
    <div className='privacy_head'>
      <div className='image_c'>
        <img src={q2h} alt='img' className='p_log' onClick={() => handleNavigate('/')} />
       
      </div>
      <div className='pr-hd'>
        <p onClick={() => handleNavigate('/login/Privacypolicy')} className='p_color'>Privacy Statement</p>
        <p onClick={() => handleNavigate('/login/Refundpolicy')} className='p_color'>Refund Policy</p>
      </div>
      <div className='pr-hd'>
        <p onClick={() => handleNavigate('/login/Termsofservice')}className='p_color'>Terms of Service</p>
        <p onClick={() => handleNavigate('/login/Deliverypolicy')}className='p_color'>Delivery Policy</p>
        


      </div>
    </div>
  )
}

export default Privacy
