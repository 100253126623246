import React from "react";
import location from "../../Assests/pin.png";
import call from "../../Assests/call.png";
import "./Footer.css";
import q2h from "../../Assests/q2h.png";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const handleNavigate = (str) => {
    navigate(str);
  };
  return (
    <div className="fooot">
      <div className="Footer_head">
        <div className="footer_1">
          <img src={q2h} alt="img" className="logo" />
        </div>
        <div className="footer_2">
          <p className="ta">Contact Us</p>
          <div className="head_1">
            <img src={location} alt="img" className="loc" />
            
           <span className="new_align">  B-6, Sowripalayam -<br /> Masakalipalayam Road,
              <br /> Periyar Nagar, <br />
              Hopes College,Coimbatore.</span> 
           <span></span>
          </div>
          <div className="head2">
            <div>
            <img src={call} alt="img" className="loc" />
            </div>
            <div>
            <span className="f1">+9190194 99907</span>
            </div>
          </div>
        </div>
        <div className="footer_3">
          <p className="tc">Opening Hours</p>
          <div>
            <span className="f1">Mon-Sun <br/>06.00 am - 9.00 pm</span>
          </div>
        </div>
        <div className="footer_4">
          <p className="tc">Terms and Conditions</p>
          <span className="f2" onClick={() => handleNavigate("Privacypolicy")}>
            Privacy Statement
          </span>
          <span className="f2" onClick={() => handleNavigate("Refundpolicy")}>
            Refund Policy
          </span>
          <span className="f2" onClick={() => handleNavigate("Termsofservice")}>
            Terms of Service
          </span>
          <span className="f2" onClick={() => handleNavigate("Deliverypolicy")}>
            Delivery Policy
          </span>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright ©2023 All rights reserved | Falcon Square</p>
      </div>
    </div>
  );
}

export default Footer;
