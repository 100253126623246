import React from "react";
import "./Header.css";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Header() {
  const name = localStorage.getItem("name");
  const email = localStorage.getItem("email");
  const mobileNumber = localStorage.getItem("mobileNumber");
  const navigate = useNavigate();
  // Check if any of the values are missing
  const isValuesPresent = name && email && mobileNumber;

  return (
    <div className="TOheader">
    {isValuesPresent ? (
      <div className="mainer">
        <div className="headbar">
          <h3>{name}</h3>
        </div>
        <div className="bardown">
          <p>{email}</p>
          <p>{mobileNumber}</p>
        </div>
      </div>
    ) : null}
  </div>
  
  );
}

export default Header;
