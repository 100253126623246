import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, addItems } from "../../Components/Redux/cartActions";
import "./Cartscreen.css";
import q2h from "../../Assests/q2h.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { VscAccount } from "react-icons/vsc";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlinePayment } from "react-icons/md";
import { MdFileDownloadDone } from "react-icons/md";
import cfree from "../../Assests/cashfree.png";
import razorpay from "../../Assests/razorpay.png";
import Api from "../../Global/Api";
import { Modal } from "react-bootstrap";
import { IoIosHome } from "react-icons/io";
import { MdOutlineWork } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import cart from "../../Assests/cart-web.png";
import Lottie from "lottie-react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import ordersuccess from "../../Assests/order-success.json";

function Cartscreen() {
  const [refresh, setRefresh] = useState(false);
  const [billTotal, setBillTotal] = useState(0);
  const [couponApplied, setCouponApplied] = useState({});
  const [promoText, setPromoText] = useState("");
  const [billDiscount, setBillDiscount] = useState(0);
  const [deliveryType, setDeliveryType] = useState("self");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [branchId, setBranchId] = useState("");
  const [item_Total, setItem_Total] = useState(0);
  const [login, setLogin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [name, setName] = useState("Rakesh");
  const [number, setNumber] = useState("9999999999");
  const [mailId, setmailId] = useState("demo@gmail.com");
  const [showModal1, setShowModal1] = useState(false);
  const [details, setDetails] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false);
  const [lg, setLg] = useState(true);
  const [acc, setAcc] = useState(true);
  const [loged, setLoged] = useState(false);
  const [delivery, setDelivery] = useState(true);
  const [deladdress, setDelAddress] = useState(false);
  const [payment, setPayment] = useState(true);
  const [paymethod, setPayMethod] = useState(false);
  const [paymodal, setPayModal] = useState(false);
  const [btn, setBtn] = useState(false);
  const [Data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cod, setcod] = useState("");
  const [pay, setPay] = useState("");
  const [modaladdress, setModalAddress] = useState(false);
  const [cartempty, setCartEmpty] = useState(false);
  const [latlang, setLatLang] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartAnimation, setCartAnimation] = useState(false);
  const CallApi = new Api();
  const menu_item_image =
    "https://falconsquare.in/RN_test/q2h-test/admin/images/menu_item_icon/";

  const addToCart1 = (item1, cat) => {
    dispatch(addItems(item1, cat));
    setRefresh(!refresh);
    setCartAnimation(true);
  };

  const RemoveFromCart = (item, cat1) => {
    dispatch(removeFromCart(item, cat1));
    setRefresh(!refresh);
  };
  const TotelQtyPrice = (state) => state.TotelPrice;
  const TotalPrice3 = useSelector(TotelQtyPrice) || {
    items: 0,
    price: 0,
    menu: [],
  };
  const gettotalitems = () => {
    for (let i = 0; i < TotalPrice3.menu.length; i++) {
      setItem_Total(TotalPrice3.price);
    }
  };
  const handleOrderClick = () => {
    setShowAnimation(true);
    setShowModal1(true);
  };
  const handleInputChange = (event) => {
    // console.log("lkl",event.target.value);
    setPromoText(event.target.value);
  };
  const getUserInfo = () => {
    const _id = localStorage.getItem("branchid");
    setBranchId(_id);
    const lat = localStorage.getItem("lat");
    setLatitude(lat);
    const long = localStorage.getItem("long");
    setLongitude(long);
    getDeliveryDetails();
  };
  const calcCrow = ({ lat1, lon1, lat2, lon2 }) => {
    function toRad(Value) {
      return (Value * Math.PI) / 180;
    }
    var Radius = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var calculate = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var distance = Radius * calculate;
    return Math.round(distance);
  };
  const getDeliveryCharges = async (delivery) => {
    const data = localStorage.getItem("branchid");
    const API = `https://falconsquare.in/RN_test/q2h-test/admin/api/deliverycharge.php?bid=${data}`;
    const url = await fetch(API);
    const res = await url.json();
    const branchLatitude = parseFloat(delivery.lat);
    const branchLongitude = parseFloat(delivery.lng);

    const distance = calcCrow({
      lat1: latitude,
      lon1: longitude,
      lat2: branchLatitude,
      lon2: branchLongitude,
    });
    if (distance > Object.keys(res.charge).length) {
      const deliveryCharges = res.charge[Object.keys(res.charge).length - 1];
      setDeliveryCharges(deliveryCharges);
      setRefresh(!refresh);
    } else {
      const deliveryCharges = res.charge[distance <= 0 ? 1 : distance];
      setDeliveryCharges(deliveryCharges);
      setRefresh(!refresh);
    }
  };

  const getDeliveryDetails = async () => {
    const data = localStorage.getItem("branchid");
    const API = `https://falconsquare.in/RN_test/q2h-test/admin/api/get_fssai.php?flag=4&branch=${data}`;
    const url = await fetch(API);
    const res = await url.json();
    getDeliveryCharges(res.data);
  };
  const getPromocode = async () => {
    const get_coupon = `https://falconsquare.in/RN_test/q2h-test/admin/api/get_coupon.php?bid=${branchId}`;
    const data = await fetch(get_coupon);
    const res = await data.json();
    for (let i = 0; i < res.promo.length; i++) {
      const TEXT = res.promo[i].promo_code;
      const promo = promoText;
      if (TEXT === promo) {
        const item = res.promo[i];
        // console.log("item",item);
        if (item.promo_type === "percent") {
          setBillDiscount(
            parseFloat((item_Total / 100) * parseFloat(item.discount_pa))
          );
          //   console.log("yjk",  parseFloat((item_Total / 100) * parseFloat(item.discount_pa)));
        } else {
          setBillDiscount(parseFloat(item.discount_pa));
          //   console.log("prvb",parseFloat(item.discount_pa));
        }
        setCouponApplied(item);
      } else {
        alert("Invalid promo code");
      }
    }
  };

  const handleModalClose = () => {
    setShowModal1(false);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };
  const handleLogin = () => {
    setLogin(true);
    setSignup(false);
  };

  useEffect(() => {
    const data = localStorage.getItem("TotalItems");
    const datae = JSON.parse(data);
    if (datae) {
      setData(datae);
    }
  }, []);
  const handleSignup = () => {
    setSignup(true);
    setLogin(false);
  };
  const handleSubmit = () => {
    localStorage.setItem("Name", name);
    localStorage.setItem("Mobile", number);
    setDetails(true);
    setSignup(false);
    setLg(false);
    setAcc(false);
    setLoged(true);
    setDelivery(false);
    setDelAddress(true);
  };
  const paySubmit = () => {
    setPayment(false);
    setPayMethod(true);
    setModalAddress(true);
    setShowModal(true);
  };

  const paymentModal = () => {
    setPayModal(true);
  };
  const handleRadio = () => {
    setBtn(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (TotalPrice3.menu.length > 0) {
      setCartEmpty(false);
    } else {
      setCartEmpty(true);
    }
  }, [TotalPrice3.menu]);
  const getSubTotal = () => {
    let ItemTotal = item_Total;
    let tempDeliveryCharges = 0;
    if (deliveryType == "home") {
      tempDeliveryCharges += parseFloat(deliveryCharges);
    } else if (deliveryType == "self") {
      setDeliveryCharges(0);
      tempDeliveryCharges = 0;
    }
    let Discount = 0;
    if (
      ItemTotal < couponApplied.limit_price ||
      ItemTotal < couponApplied.discount_pa
    ) {
      setBillDiscount(0);
      setCouponApplied({});
    } else if (couponApplied.promo_type == "percent") {
      Discount = parseFloat(
        (item_Total / 100) * parseFloat(couponApplied.discount_pa)
      );
      setBillDiscount(Discount);
    } else Discount = parseFloat(billDiscount);

    setBillTotal(ItemTotal + tempDeliveryCharges - Discount);
  };
  const callPageChanged = () => {
    getUserInfo();
    gettotalitems();
  };
  useEffect(() => {
    callPageChanged();
  }, [refresh]);

  useEffect(() => {
    getSubTotal();
  }, [item_Total, billDiscount, deliveryCharges]);

  const handleOnlinePayment = async () => {
    const paymentMethod = "razorpay";
    const orderID = 1523;
    const billTotal = 1500;
    let API;

    if (paymentMethod === "razorpay") {
      CallApi.getRazorPayToken({
        billTotal: billTotal,
      }).then((tokenDataRespons) => {
        try {
          if (paymentMethod == "razorpay" && tokenDataRespons.success == "1") {
            CallApi.updateOrderId({
              orderID,
              tokenId: tokenDataRespons.oid,
              paymentMethod,
            }).then(async (ressponse) => {
              const mail_id = mailId;
              const mobile_no = number;
              var option = {
                key: "rzp_test_hYbJeTy5EpHEJM", // Enter the Key ID generated from the Dashboard
                amount: billTotal, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: "INR",
                name: "Falcon Square", //your business name
                description: "Falcon Square Test",
                Image:
                  "https://falconsquare.in/RN_test/q2h-test/admin/images/logo/logo.png",
                order_id: orderID, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
                prefill: {
                  //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
                  name: "Gaurav Kumar", //your customer's name
                  email: "gaurav.kumar@example.com",
                  contact: "9000090000", //Provide the customer's phone number for better conversion rates
                },
                notes: {
                  address: "Falcon Square",
                },
                theme: {
                  color: "#3399cc",
                },
              };
              option.handler = (res, error) => {
                option.res = res;
                console.log(res);
              };
              // var raz = new Razorpay(option);
              // raz.open();
            });
          }
        } catch {}
      });
    }
  };

  //Map Show Functions Start
  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  const center = {
    lat: -3.745,
    lng: -38.523,
  };
  const getLocations = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log(latitude, longitude);
          setLatLang(latitude, longitude);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const { isLoaded } = useJsApiLoader({
    //   id: "google-map-script",
    //   googleMapsApiKey: "AIzaSyBI128TbOGiD60MgIoxXXO_KtJN_PP2a8U",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(latlang);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    getLocations();
  }, [latlang]);

  //Map Function End

  useEffect(() => {
    gettotalitems();
  }, [refresh]);

  return (
    <div className="container">
      {cartempty ? (
        <div>
          <div className="img-cart p-3">
            <img
              src={q2h}
              alt="img"
              className="img-fluid"
              onClick={() => navigate("/BranchPage")}
            />
          </div>
          <div className="d-flex justify-content-center">
            <img src={cart} className="img-addtocart img-fluid" alt="Cart" />
          </div>

          <div className="d-flex flex-column align-items-center">
            <h4 className="bold">Your Cart is empty</h4>
            <h6>You can go to the main page to add products</h6>
            <button className="btn-order" onClick={() => navigate("/mainpage")}>
              Order Now
            </button>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="img-cart p-5">
            <img
              src={q2h}
              alt="img"
              className="img-fluid"
              onClick={() => navigate("/BranchPage")}
            />
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="category1">
              <div className="headline">
                <h1 style={{ fontWeight: "bold" }}>My Order</h1>
              </div>
              <div className="">
                <div className="d-flex flex-wrap justify-content-between">
                  {TotalPrice3.menu.map((item, itemIndex) => (
                    <div key={itemIndex} className="mb-3 col-md-12">
                      <div>
                        {item.data.map(
                          (cat, catIndex) =>
                            cat.qty > 0 && (
                              <div className="card-sh m-2 " key={catIndex}>
                                <div className="menu-container ">
                                  <div className="image-container">
                                  <img
                                      src={`${menu_item_image}${cat.menu_image}`}
                                      className="cartt_image1 img-fluid"
                                      alt={cat.menu_name}
                                    />
                                  </div>
                                  <div className="text-container">
                                    <h3 className="menu-name bold">
                                      {cat.menu_name}
                                    </h3>
                                    <h3 className="menu-description1">
                                      {cat.description}
                                    </h3>
                                    {cat.qty > 0 ? (
                                      <div className="quantity-container p-3">
                                        {cat.qty > 0 ? (
                                          <div className="add_btn_out">
                                            <button
                                              className="quantity-btn"
                                              onClick={() => {
                                                RemoveFromCart({
                                                  item: itemIndex,
                                                  cat1: catIndex,
                                                });
                                                setRefresh(!refresh);
                                              }}
                                            >
                                              -
                                            </button>
                                            <h6>{Number(cat.qty)}</h6>
                                            <button
                                              className="quantity-btn"
                                              onClick={() => {
                                                addToCart1({
                                                  item1: itemIndex,
                                                  cat: catIndex,
                                                  catId: cat.id,
                                                  itmId: item.cat_id,
                                                });
                                                setRefresh(!refresh);
                                              }}
                                              id="addtocart"
                                            >
                                              +
                                            </button>
                                          </div>
                                        ) : (
                                          <button
                                            className="add_btn"
                                            onClick={() => {
                                              addToCart1({
                                                item1: itemIndex,
                                                cat: catIndex,
                                                catId: cat.id,
                                                itmId: item.cat_id,
                                              });
                                              setRefresh(!refresh);
                                            }}
                                          >
                                            ADD
                                            <span className="cart-item"></span>
                                          </button>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="page-wrapper1">
                                        <button
                                          className={`btn btn-success add_btn1 ${
                                            cartAnimation ? "sendtocart1" : ""
                                          }`}
                                          onClick={() =>
                                            addToCart1({
                                              item1: itemIndex,
                                              cat: catIndex,
                                            })
                                          }
                                        >
                                          ADD
                                          <span className="cart-item1"></span>
                                        </button>
                                      </div>
                                    )}
                                    <div className="p-1 m-2">
                                      <p className="menu-price1 m-1">
                                        {cat.price}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-12">
            <Modal>
            <div class="category_name1 position-relative pt-0">
              <div className="os-out-div">
                <h2 class="summary_heading">Order Summary</h2>
              </div>
              <div className="os-under-div">
                <div className="d-flex flex-column order_align">
                  <h5
                    style={{
                      color: "white",
                      fontSize: "20px",
                      marginBottom: "7px",
                    }}
                  >
                    Delivery Type
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="form-check mr-3">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          name="deliveryType"
                          checked={deliveryType === "self"}
                          onChange={() => {
                            setDeliveryType("self");
                            setDeliveryCharges(0);
                          }}
                        />{" "}
                        Self Pickup
                      </label>
                    </div>
                    <div className="form-check ">
                      <label className="form-check-label p-2">
                        <input
                          type="checkbox"
                          name="deliveryType"
                          checked={deliveryType === "home"}
                          onChange={() => setDeliveryType("home")}
                        />{" "}
                        Home Delivery
                      </label>
                    </div>
                  </div>
                </div>

                {TotalPrice3.menu.map((cat, catIndex) => (
                  <div key={catIndex} className="mb-3">
                    <div className="summary_name">
                      {cat.data.map(
                        (item, itemIndex) =>
                          item.qty > 0 && (
                            <div key={itemIndex}>
                              <div className="summary_fullname">
                                <div className="d-flex justify-content-between">
                                  <div className="summary_qty">{item.qty}</div>{" "}
                                  <span
                                    style={{
                                      color: "white",
                                      padding: "0px 3px",
                                    }}
                                  >
                                    x{" "}
                                  </span>
                                  <div className="summary_menu_name">
                                    {item.menu_name}
                                  </div>
                                </div>
                                <div className="summary_menu_name">
                                  {item.price * item.qty}
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                ))}
                <div className="d-flex justify-content-between p-2">
                  <div className="text-white p-2">COUPON </div>
                  <div className="input-group">
                    <input
                      type="text"
                      onChange={handleInputChange}
                      className="form-control p-1 "
                      style={{ maxWidth: "150px" }}
                    />
                    <button
                      className="btn-apply"
                      onClick={() => {
                        if (billDiscount <= 0) {
                          getPromocode();
                        } else {
                          setBillDiscount(0);
                          setCouponApplied({});
                        }
                      }}
                    >
                      {billDiscount <= 0 ? "Apply" : "Remove"}
                    </button>
                  </div>
                </div>
                <div style={{ color: "white" }}>
                  Coupon: {`\u20B9${billDiscount}`}
                </div>
                {deliveryType == "home" && (
                  <div style={{ color: "white" }}>
                    Delivery Charge :{`\u20B9${deliveryCharges}`}
                  </div>
                )}
                <div style={{ color: "white" }}>Total Price: {billTotal}</div>
              </div>
             
            </div>
            </Modal>
            <div className="login_allow">
              <div className=" col-sm-5 mb-5 pay_card">
                <div className="steper">
                  <VscAccount className="icon_1" />
                  <p className="dashed_ln"></p>
                  {login && <p className="dashed_ln"></p>}
                  {signup && <p className="dashed_ln2"></p>}
                  <IoLocationOutline className="icon_1" />
                  <p className="dashed_ln"></p>
                  <MdOutlinePayment className="icon_1" />
                </div>
                <div>
                  {acc && <h3 className="ac">Account</h3>}
                  {loged && (
                    <h3 className="ac">
                      Logged In <MdFileDownloadDone className="tick" />
                    </h3>
                  )}
                  {lg && (
                    <div className="btn_split">
                      <button
                        className="prcd_login1"
                        onClick={() => handleLogin()}
                      >
                        Have an account?
                        <br />
                        LOG IN
                      </button>

                      <button
                        className="prcd_login"
                        onClick={() => handleSignup()}
                      >
                        New to Q2H? <br />
                        SIGN UP
                      </button>
                    </div>
                  )}
                  {login && (
                    <div className="inpt_lg">
                      <input
                        type="text"
                        placeholder="Enter your mobile number"
                        className="mob_number"
                        autoFocus
                      />
                      <button className="LOGIN">LOGIN</button>
                    </div>
                  )}
                  {signup && (
                    <div className="Signup_lg">
                      <input
                        type="text"
                        value={number}
                        onChange={handleNumberChange}
                        placeholder="Phone number"
                        className="Signup_pop"
                        autoFocus
                      />
                      <input
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                        placeholder="Name"
                        className="Signup_pop"
                      />
                      <input
                        type="text"
                        placeholder="Email"
                        className="Signup_pop"
                      />
                      <button className="LOGIN" onClick={handleSubmit}>
                        Continue
                      </button>
                    </div>
                  )}
                  {details && (
                    <div className="inputtt">
                      <h4>{name}</h4>|<h4>{number}</h4>
                    </div>
                  )}
                </div>
                {delivery && (
                  <div>
                    <h4 className="delivery_ad">Delivery address</h4>
                  </div>
                )}
                {deladdress && (
                  <div className="ad_address">
                    <h4>Enter your delivery address</h4>
                    <button className="add_address" onClick={paySubmit}>
                      Add Address
                    </button>
                  </div>
                )}

                {payment && (
                  <div>
                    <h4 className="delivery_ad">Payment</h4>
                  </div>
                )}
                {paymethod && (
                  <div className="paymeth_1">
                    <button className="paymeth" onClick={paymentModal}>
                      Choose Payment Method
                    </button>
                  </div>
                )}
                {paymodal && (
                  <div className="card col-sm-12   pay_mod">
                    <button
                      className="cas_btn "
                      onClick={() => {
                        setBtn(false);
                      }}
                    >
                      Cash On Delivery <input type="radio" name="payment" />
                    </button>
                    <button className="cas_btn">
                      Pay{" "}
                      <input
                        type="radio"
                        name="payment"
                        onClick={handleRadio}
                        value={pay}
                      />
                    </button>
                  </div>
                )}
                {btn && (
                  <div className="upi_pay">
                    <button className="cash_free_btn">
                      {" "}
                      <img src={cfree} alt="img" className="cash_free" />
                    </button>
                    <button
                      className="cash_free_btn"
                      onClick={() => handleOnlinePayment()}
                    >
                      {" "}
                      <img src={razorpay} alt="img" className="razor_pay" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            Save delivery address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="map-container m-3">
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={latlang}
                    zoom={10}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                  >
                    <Marker position={latlang} />
                  </GoogleMap>
                ) : null}
              </div>

              <div className="col">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Address"
                />
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Door / Flat No"
                />
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Landmark"
                />
                <div className="d-flex justify-content-between mt-2">
                  <button className="home-work-other">
                    <IoIosHome />
                    Home
                  </button>
                  <button className="home-work-other">
                    <MdOutlineWork />
                    Work
                  </button>
                  <button className="home-work-other">
                    <FaLocationDot />
                    Other
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col mt-3">
                <button className="save_address_1 w-100">
                  SAVE ADDRESS & PROCEED
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div>
        <button onClick={() => handleOrderClick()}>order</button>
        <Modal show={showModal1} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center w-100">
              Order Status
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h1 className="d-flex justify-content-center">Order Success...!</h1>
            <Lottie
              animationData={require("../../Assests/order-success.json")}
              loop={true}
              height={200}
              width={200}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Cartscreen;








